import React, { Component } from 'react';
import { Grid, Cell, List, ListItem, ListItemContent } from 'react-mdl';
import profile from '../assets/prof.jpg'

class Contact extends Component{
    render(){
        return (
            <section>
                <div class="contact-parent">
                    <div class="container-contact" id="center">
                        <div class="form-group">
                            <label for="firstName"> First Name</label>
                            <input type="text" id="firstName" name="firstName"></input>
                        </div>

                        <div class="form-group">
                            <label for="latsName">Last Name</label>
                            <input type="text" id="lastName" name="lastName"></input>
                        </div>

                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="email" id="email" name="email"></input>
                        </div>

                        <div class="form-group">
                            <label for="message">Message</label>
                            <textarea name="message" id="message" cols="30" rows="10"></textarea>
                        </div>

                        <button type="submit">Submit</button>
                    
                    </div>
                </div>
        </section>
        )
    }
}

export default Contact;