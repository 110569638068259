import React, { Component, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import chicken from '../assets/cooking/chicken-marsala.jpg';
import eggsben from '../assets/cooking/eggs-benedict.jpg';
import moltencake from '../assets/cooking/molten_cake.jpg';
import pork from '../assets/cooking/honeypork.jpg';
import breast from '../assets/cooking/chicken-breast.jpg';


class Cooking extends Component{
    render(){
        return (
            <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={chicken}
                    alt="First slide"
                    />
                    <Carousel.Caption style={{color: 'black'}}>
                    <h3>Chicken Marsala with Gremolata</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={eggsben}
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h3>Eggs Benedict with Bacon and Avocado</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={moltencake}
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h3>Chocolate Molten Lava Cake </h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={pork}
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h3>Garlic Honey Glazed Pork with Fresh Bell Peppers </h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={breast}
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h3>Chicken Breast with Sides</h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        );
    }
}
export default Cooking;