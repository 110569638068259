import React from 'react';
import {Switch, Route} from 'react-router-dom';

import Landing from './landing';
import About from './about';
import Experience from './experience';
import Projects from './projects';
import Cooking from './cooking';
import Contact from './contact';

const Main = () => (
    <Switch>
        <Route exact path = "/" component={Landing} />
        <Route path = "/about" component={About} />
        <Route path = "/projects" component={Projects} />
        <Route path = "/experience" component={Experience} />
        <Route path = "/cooking" component={Cooking} />
        <Route path = "/contact" component={Contact} />
    </Switch>
)

export default Main;