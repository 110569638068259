import React from 'react';
import {Link} from 'react-router-dom';
import './App.css';
import { Layout, Header, Navigation, Drawer, Content } from 'react-mdl';
import Main from './components/main';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Text, StyleSheet } from "react-native";

function App() {
  return (
    <div class="mdl-layout mdl-js-layout">
  <header class="mdl-layout__header mdl-layout__header--scroll header-color">
    <div class="mdl-layout__header-row">
      <nav class="mdl-navigation">
        <a class="mdl-navigation__link" href="/">Sarth Shah</a>
      </nav>
      <div class="mdl-layout-spacer"></div>
      <nav class="mdl-navigation">
        <a class="mdl-navigation__link" href="/about">About</a>
        <a class="mdl-navigation__link" href="/experience">Experience</a>
        {/* <a class="mdl-navigation__link" href="/projects">Projects</a>
        <a class="mdl-navigation__link" href="/cooking">Cooking</a> */}
        {/* <a class="mdl-navigation__link" href="/contact">Contact</a> */}
        

      </nav>
    </div>
  </header>
  <div class="mdl-layout__drawer">
    <nav class="mdl-navigation">
        <a class="mdl-navigation__link" href="/">Sarth Shah</a>
        <a class="mdl-navigation__link" href="/about">About</a>
        <a class="mdl-navigation__link" href="/experience">Experience</a>
        {/* <a class="mdl-navigation__link" href="/projects">Projects</a>
        <a class="mdl-navigation__link" href="/cooking">Cooking</a> */}
        {/* <a class="mdl-navigation__link" href="/contact">Contact</a> */}
    </nav>
  </div>
  <main class="mdl-layout__content">
    <div class="page-content">
      <Main/>
    </div>
  </main>
</div>
  );
}

const styles = StyleSheet.create({
  baseText: {
    fontFamily: "Avenir"
  }
});

export default App;
