import React, { Component } from 'react';
import {Cell, Grid} from 'react-mdl';


class Projects extends Component{
    render(){
        return (
            
            <Grid>
                <Cell col={4}>
                        {/*Project 1*/}
                        <div class="demo-card-square mdl-card mdl-shadow--2dp">
                            <div class="mdl-card__title mdl-card--expand">
                                <h2 class="mdl-card__title-text">Update</h2>
                            </div>
                            <div class="mdl-card__supporting-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Aenan convallis.
                            </div>
                            <div class="mdl-card__actions mdl-card--border">
                                <a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect">
                                View Updates
                                </a>
                            </div>
                        </div>
                </Cell>

                    <div class="mdl-layout-spacer"></div>
                
                <Cell col={4}>
                        {/*Project 2*/}
                        <div class="demo-card-square mdl-card mdl-shadow--2dp">
                            <div class="mdl-card__title mdl-card--expand">
                                <h2 class="mdl-card__title-text">Update</h2>
                            </div>
                            <div class="mdl-card__supporting-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Aenan convallis.
                            </div>
                            <div class="mdl-card__actions mdl-card--border">
                                <a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect">
                                View Updates
                                </a>
                            </div>
                        </div>

                    </Cell>

                        <div class="mdl-layout-spacer"></div>

                    <Cell col={4}>
                        {/*Project 3*/}
                        <div class="demo-card-square mdl-card mdl-shadow--2dp">
                            <div class="mdl-card__title mdl-card--expand">
                                <h2 class="mdl-card__title-text">Update</h2>
                            </div>
                            <div class="mdl-card__supporting-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Aenan convallis.
                            </div>
                            <div class="mdl-card__actions mdl-card--border">
                                <a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect">
                                View Updates
                                </a>
                            </div>
                        </div>

                    </Cell>
                        <div class="mdl-layout-spacer"></div>
            </Grid>
        )
    }
}
export default Projects;