import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import l2w from '../assets/learn-to-win2.png'
import ibm from '../assets/IBM-Logo.png'
import poli from '../assets/logo-white.png'
import {Helmet} from 'react-helmet';
import { Text, StyleSheet } from "react-native";

class Experience extends Component{
    render(){
        return (
      <body>
          <Helmet>
                    <style>{'body { background-color: #2a2a2a; }'}</style>
          </Helmet>
            <div class ="mdl-grid">
              {/*Job 1 Learn to Win*/}

              <div class="mdl-cell mdl-cell--4-col mdl-cell--middle">
                <div class="company-img">
                  <img src={l2w} 
                        alt="Learn to Win Logo" 
                        style={{height: 'auto', width: '325px', justifyContent: 'center'}}/>
                </div>
              </div>
              <Cell col={8}>
              <Text style={styles.baseText}>

                  <div class="demo-card-wide mdl-card mdl-shadow--2dp">
                    <div class="mdl-card__title" style={{color:'white', verticalAlign: 'text-top'}}>
                      <h2 class="mdl-card__title-text"> Learn to Win Customer Discovery Intern (June - August 2020)</h2>
                    </div>
                    <div class="mdl-card__supporting-text">
                      Pioneered Customer Discovery and Market Analysis in the Healthcare Industry
                    </div>
                    <div class="mdl-card__supporting-text">
                      Developed and designed a new company-wide marketing deck
                    </div>
                    <div class="mdl-card__supporting-text">
                      Conducted a gap assessment of FedRAMP compliance security controls
                    </div>
                    
                    
                    
                    
                    <div class="mdl-card__actions mdl-card--border">
                      <a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
                      href="https://www.learntowin.us/" style={{color: "#8FDAFF"}} target="_blank">Learn to Win </a>
                
                    </div>
                    
                    {/* <div class="mdl-card__menu">
                      <button class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect">
                        <a class="material-icons" href= "https://www.linkedin.com/in/sarthshah/" target="_blank">share</a>
                      </button>
                    </div> */}
                  </div>
              </Text>

              </Cell>

              {/*Job 2 IBM*/}

              <div class="mdl-cell mdl-cell--4-col mdl-cell--middle">
                <div class="company-img">
                  <img src={ibm} 
                        alt="IBM Logo" 
                        style={{height: 'auto', width: '325px', textAlign: 'center', justifyContent: 'center'}}/>
                </div>
              </div>
              <Cell col={8}>
                
              <Text style={styles.baseText}>
                  <div class="demo-card-wide mdl-card mdl-shadow--2dp">
                    <div class="mdl-card__title" style={{color:'white', verticalAlign: 'text-top'}}>
                      <h2 class="mdl-card__title-text"> IBM Software Engineering Intern (June - August 2019)</h2>
                    </div>
                    <div class="mdl-card__supporting-text">
                      Spearheaded improvement for DB2 LUW chatbot through NLP, machine learning, and data analysis
                    </div>
                    <div class="mdl-card__supporting-text">
                      Automated analysis of incoming support cases by developing data scrapers using Selenium
                    </div>
                    <div class="mdl-card__supporting-text">
                      Deployed an end to end application with CICD pipeline and containerization through Docker
                    </div>
                    
                    
                    
                    
                    <div class="mdl-card__actions mdl-card--border">
                      <a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
                      href="https://www.ibm.com/us-en/" style={{color: "#8FDAFF"}} target="_blank">IBM</a>
                
                    </div>
                    {/* <div class="mdl-card__menu">
                      <button class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect">
                        <i class="material-icons">share</i>
                      </button>
                    </div> */}
                  </div>
                </Text>
              </Cell>

              <div class="mdl-layout-spacer"></div>
              {/*Job 3 Polimorphic*/}

              <div class="mdl-cell mdl-cell--4-col mdl-cell--middle">
                <div class="company-img">
                  <img src={poli} 
                      alt="poli Logo" 
                      style={{height: 'auto', width: '275px', textAlign: 'center', justifyContent: 'center'}}/>
                </div>
              </div>
              <Cell col={8}>
                
              <Text style={styles.baseText}>
                  <div class="demo-card-wide mdl-card mdl-shadow--2dp">
                    <div class="mdl-card__title" style={{color:'white', verticalAlign: 'text-top'}}>
                      <h2 class="mdl-card__title-text"> Polimorphic Software Engineering Intern (June - August 2018)</h2>
                    </div>
                    <div class="mdl-card__supporting-text">
                      Developed backend data-scrapers in Python using Requests, Beautiful Soup, and Selenium
                    </div>
                    <div class="mdl-card__supporting-text">
                      Improved UX/UI by redesigning components in website frontend and surveying user responses
                    </div>
                    <div class="mdl-card__supporting-text">
                      Optimized and defined relational schema on a production scale PostgreSQL Database
                    </div>
                    
                    
                    
                    
                    <div class="mdl-card__actions mdl-card--border">
                      <a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
                      href="https://www.polimorphic.com/" style={{color: "#8FDAFF"}} target="_blank">Polimorphic</a>
                
                    </div>
                    {/* <div class="mdl-card__menu">
                      <button class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect">
                        <i class="material-icons">share</i>
                      </button>
                    </div> */}
                  </div>

                </Text>

              </Cell>
        
            </div>
        </body>
        )
    }
}

const styles = StyleSheet.create({
  baseText: {
    fontFamily: "Avenir",
    color: "white"
  }
});


export default Experience;