import React, { Component } from 'react';
import {Grid, Cell} from 'react-mdl/lib/Grid';
import sarthText from '../assets/sarthText.ai'
//import { setCustomText } from 'react-native-global-props';

const customTextProps = { 
    style: { 
      fontFamily: "Avenir"
    }
}

class Landing extends Component{
    
    render(){
        return (

<div style={{width: '100%', margin: 'auto'}} className="landing-grid">
        
    <div className="banner-text">
      <h1>Hi I'm Sarth :)</h1>
    </div> 
</div>

        )
    }
}

export default Landing;