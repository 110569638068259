import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import profile from '../assets/prof.jpg'
import { Text, StyleSheet } from "react-native";

class About extends Component{
    render(){
        return (
            <body>
                <Helmet>
                    <style>{'body { background-color: #2a2a2a; }'}</style>
                </Helmet>
                <div class = "about-page" >
                    <div class="mdl-grid">
                        <div class="mdl-cell mdl-cell--7-col">
                            <Text style={styles.baseText}>
                                <h2 style={{ width: '75%', margin: 'auto', paddingTop: '1em'}}>About Me</h2>
                                <p style={{ width: '75%', margin: 'auto', paddingTop: '1em', fontSize: '16px'}}>
                                I recently graduated from UC Berkeley with a B.S. in Electrical Engineering and Computer Science (EECS)! Going into Berkeley I had many goals. However, there was one goal that was most important to me. Before my first day at Berkeley, I had heard many horror stories. I heard about the rigor, the depression, the stress, etc. That’s when I made the goal to take one class or do one activity purely for fun every semester. Looking back on my Berkeley experience, this single goal is what I’m most proud about and most happy about. For any freshmen starting their journey at Berkeley, my biggest piece of advice would be having this goal. </p>

                                <p style={{ width: '75%', margin: 'auto', paddingTop: '1em', fontSize: '16px'}}>
                                The first activity I learned was from the Cardcounting and Blackjack DeCal. For those who aren’t aware, a DeCal is usually a 1 or 2 unit class that is taught by students and can be about almost anything. After a rigorous semester of training, I was ready to take my talents to one of the local casinos. I walked in with $25 dollars. I walked out with $23. Yes. I know. I didn’t strike it rich or get mauled by a pit boss, but I did accomplish playing blackjack for an hour and basically breaking even. That was a win in my books. </p>
                                
                                <p style={{ width: '75%', margin: 'auto', paddingTop: '1em', fontSize: '16px'}}>
                                The next activity I took was a Drake DeCal. Yes, so mainstream of me. But after that Drake Sprite commercial way back when, I have always been in love with his music. Interestingly enough, going into the DeCal I thought I would get sick of his music because I already listened to his music so much. The complete opposite happened. I gained so much more love for his music and found so many hidden gems. </p>
                                

                                <p style={{ width: '75%', margin: 'auto', paddingTop: '1em', fontSize: '16px'}}>
                                My favorite class in high school was Psychology. There was something so interesting about learning “Why we do what we do”. That’s what pushed me to take a Criminal Psychology DeCal. This was easily one of the most interesting classes I had ever taken.  If you’re interested in Criminal Psychology, I highly recommend the show Mindhunter on Netflix! </p>
                               
                                
                                <p style={{ width: '75%', margin: 'auto', paddingTop: '1em', fontSize: '16px'}}>
                                Growing up, I loved to cook and it felt natural to me. With cooking, the concept of tasting and adjusting as you go made so much sense to me. This is why Baking always confused me. The idea that once you put your dish in the oven, you can't make any monumental adjustments makes the process of baking fascinating. I see baking almost like writing some piece of code and without doing any debugging or error testing you deploy this code to production and hope for the best. This is what inspired me to take the Baking DeCal at Berkeley. This was the most influential DeCal I took. Not only did it provide me with the skillset of a baker, but it also ignited my passion for cooking even more. Today, I am constantly learning new dishes, techniques, and cuisines.</p>


                                <p style={{ width: '75%', margin: 'auto', paddingTop: '1em', fontSize: '16px'}}>
                                The next activity I started was rock climbing. This was definitely out of the norm for me, but a few of my friends were interested and wanted to start consistently climbing more. I jumped in head first, buying the harness, the shoes, the membership (fyi: rock climbing is expensive). I’m so glad me and my friends took this leap because rock climbing made junior year so much more fun. Junior year was probably the most intense year academically, especially with behemoths such as Operating Systems and Machine Learning. Regardless of how difficult it got, me and my friends would always climb 3 times a week and that was some of the most fun I’ve ever had.</p>

                                <p style={{ width: '75%', margin: 'auto', paddingTop: '1em', fontSize: '16px'}}>
                                There was a brief moment where I was debating on minoring in Music. I had always loved music and after my first semester of Junior year I only had one more required class to get my Electrical Engineering and Computer Science (EECS) degree. Although it was tempting, I decided to simply take whichever music classes I was interested in. This is when my next activity of music at Berkeley began. I first took a musicianship class where we learned basic rhythm, music theory, and pitch. Then I took my all time favorite class at Berkeley: Music 44, Voice Class. I had always loved to sing, but I had never properly learned. We had such an amazing professor, and I saw so much improvement in my voice it almost felt magical. </p>

                                <p style={{ width: '75%', margin: 'auto', paddingTop: '1em', fontSize: '16px'}}>
                                The last activity I took up before graduating was the iOS DeCal. Although backend programming interested me, there was something unique about understanding UI and frontend design that I was always interested in. This DeCal was definitely a watershed moment for my programming career. I developed a completely new skillset as well as an appreciation for UI design.</p>
                                
                                <p style={{ width: '75%', margin: 'auto', paddingTop: '1em', fontSize: '16px'}}>
                                My whole life can be explained through my various passion filled journeys and random pursuits. At the end of the day, there is so much to Berkeley. The amazing friends you’ll make. The midnight excursions for food. The challenging workload that seems to never end. The constant flyers being plunged into your face through the expansive Sproul Plaza. The beautiful towering Campanile piercing the night sky. But all of these are inevitable. What isn’t guaranteed in the Berkeley experience is the pursuit of your personal interests. This pursuit is what will make your Berkeley experience unique to you.</p>

                                <p style={{ width: '75%', margin: 'auto', paddingTop: '1em', fontSize: '16px'}}>
                                I’m excited to see what new pursuits and activities I take up in this new post graduate life! </p>
                                
                            </Text>
                        </div>
                        <div class="mdl-cell mdl-cell--4--col profile-img">
                            <img src={profile} alt="Profile"
                            style={{ width: '85%', margin: 'auto', paddingTop: '2em'}}/>
                        </div>
                    </div>
                </div>
            </body>
        )
    }
}

const styles = StyleSheet.create({
    baseText: {
      fontFamily: "Avenir",
      color: "white"
    }
  });


export default About;
